<template>
  <div class="main-content">
    <h3 class="marginBottom20">断电异常设置</h3>
    <div style="text-align:right">
      <el-button
        type="primary"
        @click="handleSave"
        style="margin-bottom:20px"
        :loading="loading"
        >{{ disabled ? "编辑" : "保存" }}</el-button
      >
    </div>
    <el-card>
      <div  class="flex ">
        <span style="color: #ff0000;margin-top: 6px;">*</span>
        <el-descriptions title="" :column="1" :colon="false">
          <el-descriptions-item label="断电 ">
            <div class="flex align-items">
              <el-input-number
                v-model="form.num"
                :disabled="disabled"
                placeholder="请输入"
                :precision="0"
                :max="999"
                :min="0"
                :controls="false"
                @blur="salaryChange($event, 'consumeIntimacy', 999)"
              ></el-input-number>
              <span style="margin: 0 10px;width: 200px;">小时，定位断电异常</span>
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-card>
  </div>
</template>

<script>
import { interruptView, interruptUpdate } from "@/api/setting/index";
export default {
  name: "index",
  data() {
    return {
      form: {
        id: "",
        num: "",
      },
      loading: false,
      disabled: true,
    };
  },
  created() {},
  mounted() {
    this.getView();
  },
  methods: {
    async getView() {
      try {
        const { data } = await interruptView();
        // console.log(data)
        this.form = data;
      } catch (e) {
        // console.log('错误',e)
      }
    },
    handleSave() {
      if (this.disabled) {
        this.disabled = false;
        return;
      }
      if (!this.form.num && this.form.num != 0) {
        this.$message.warning("请输入断电时间");
        return;
      }
      if (this.loading) return;
      this.loading = true;
      interruptUpdate(this.form)
        .then((res) => {
          this.loading = false;
          this.$message.success("保存成功");
          this.disabled = true;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
/deep/.el-descriptions-item .el-descriptions-item__container {
  align-items: center;
}
/deep/.el-input-number.is-without-controls .el-input__inner {
  text-align: left;
}
</style>
