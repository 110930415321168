import { get, post, put, Delete } from "@/api/axios";

// 断电异常设置-查看
export const interruptView = (params) =>
  get("/web/setting/interrupt/view", params);

// 断电异常设置-修改
export const interruptUpdate = (params) =>
  put("/web/setting/interrupt/update", params);

// 不在运营区域设置-查看
export const businessView = (params) =>
  get("/web/setting/business/view", params);

// 不在运营区域设置-修改
export const businessUpdate = (params) =>
  put("/web/setting/business/update", params);

// 销售合伙人协议设置-查看
export const agreementView = (params) =>
  get("/web/setting/agreement/view", params);

// 销售合伙人协议设置-修改
export const agreementUpdate = (params) =>
  put("/web/setting/agreement/update", params);

// 车辆预约功能设置-查看
export const subscribeView = (params) =>
  get("/web/setting/subscribe/view", params);

// 车辆预约功能设置-修改
export const subscribeUpdate = (params) =>
  put("/web/setting/subscribe/update", params);

// 平台客户电话设置-列表
export const phoneList = (params) => get("/web/setting/phone/list", params);

// 平台客户电话设置-新增
export const phoneAdd = (params) => post("/web/setting/phone/add", params);

// 平台客户电话设置-删除
export const deletePhone = (params) =>
  Delete("/web/setting/phone/delete/" + params.id, "");

// 平台客户电话设置-查看
export const phoneView = (params) =>
  get("/web/setting/phone/view/" + params.id, "");

// 平台客户电话设置-修改
export const phoneUpdate = (params) => put("/web/setting/phone/update", params);

// 好友裂变赠送设置-列表
export const giveList = (params) => get("/web/setting/give/list", params);

// 好友裂变赠送设置-修改
export const giveUpdate = (params) => put("/web/setting/give/update", params);
